<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Logout',
  data () {
    return {
    }
  },
  created () {
    this.$setTheme(this.$store.getters['themes/getByLabel']('Light'))
    this.$store.dispatch('auth/logout')
    this.$router.push({ name: 'login' }).catch(err => err)
  }
}
</script>
